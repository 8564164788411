/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import '~font-awesome/css/font-awesome.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

*{
    font-family: Open Sans,sans-serif;
}
html{
    height: 100%;
}

body {
    background-color: rgb(251 251 251);
    height: 100%;
}

.form-control:focus{
    box-shadow: none;
}

.redText {
    color: red;
}
.model{
    background-color: #fff;
	max-width: 50rem;
	padding: 30px;
	border-radius: 5px;
	box-shadow: 0 0 20px rgba(0,0,0,0.3);
}
.submit_Btn {
    width: 100%;
    height: 50px;
    background-color: #ba0a0a;
    border-radius: 4px;
    color: white;
    border: none;
}

.forgot-password {
    text-align: right;
    color: #ba0a0a;
}

.error {
    color: #f25022;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.inputField {
    border: none;
}
.alignment_changes{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table_search{
    width: auto;
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.btn-grape, .btn-grape:hover{
    background-color: #962336;
    color: #ffff;
}
.primeNgButton .p-button{
    background: #962336;
    color: #ffff;
    box-shadow: none;
    border: none;
}
.light_skyBlue, .comment__box:focus{
    background-color: #f7f7fd;
}
.transparent .p-button, .transparent .p-button:hover {
    background: transparent;
    color: #962336;
    border: 1px solid #962336;
}
.primeNgButton .p-button:hover{
    background: #962336;
    color: #ffff;
}
.event__btn .p-button{
    background: white;
    color: #962336;
    border:white;
    box-shadow: none;
    border: none;
  }
  .event__btn .p-button:hover{
    background: white;
    color: #962336;
    border:white;
    box-shadow: none;
    border: none;
  }



.cancel_Btn .p-button{
    background: white;
    color: black;
    border: 1px solid black;
}
.cancel_Btn .p-button:hover{
    background: #F6F5FF;
    color: black;
}
.p-button .p-button-label{
    font-weight: normal;
  }
.bg-cloud{
    background-color: #efefef;
}
.pTable .p-datatable{
    margin-top: 1rem;
}
.p-datatable .p-datatable-header{
    border-radius: 5px 5px 0px 0px;
}
.p-datatable .p-datatable-thead > tr > th{
    background-color: #f8f9ff;
    color: #868686;
    font-size: 16px;
    font-weight: 500;
}
.p-datatable .p-datatable-tbody > tr > td{
    border: none;
}
.p-datatable .p-datatable-tbody > tr{
    border: 0.5px solid #d8d8d8;
}
.p-datatable .p-sortable-column .p-sortable-column-icon{
    color: #868686;
}
.align_inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* @media  (max-width: 576px){
    .global_search{
        width: 9rem;
    } 
}
@media  (min-width: 576px){
    .global_search{
        margin-left: 40rem;
    } 
} */
.color_darkCloud{
    color: #99A7BA;
}
.success_Class{
    color: green;
}

.bg-darkCherry{
    background-color: #F2DEDE;
}
.creamy{
    background-color: #ede2e2;
}
.text_muted{
    color: #5F6775;
}
.text__ash{
    color:#757575
}
.eye__color{
    color: #8397B9;
}
.star{
    color: red;
    padding-left: 2px;
}
.burgendy{
    color: #A41034;
}
.bg-burgendy{
    background-color: #A41034;
}
.bg-peach{
    background-color:#f7e8e8
}
.custom_border{
    border-radius: 0.25rem;
border: 1px solid #E4DEDC;
background: #FFF;
box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.08);
}
.custom_Block{
    border-radius: 10px 10px 10px 10px;  
    border: 1px solid var(--l-primary-light-93, #E5E8F7);  
    background: var(--l-primary-light-100, #FFF);  
    box-shadow: 0px 9px 18px 0px rgba(42, 83, 204, 0.08);
}
.custom_button{
    border-radius: 10px 10px 10px 10px;  
    background-color: #f6f6f7;
    box-shadow: 0px 9px 18px 0px rgba(42, 83, 204, 0.08);
    border: 1px solid var(--l-primary-light-93, #E5E8F7); 
}

.skyCloud{
    color: #455560;
}
p-dropdown{display: block; width: 100%;}
:host::ng-deep .p-dropdown{
   width: 100% !important;
}
.p-datatable .p-sortable-column .p-column-title, .p-datatable .p-sortable-column .p-sortable-column-icon, .p-datatable .p-sortable-column .p-sortable-column-badge{
    font-size: 12px;
}

.p-dropdown{
    height: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}
.p-calendar{
    height: 2.5rem;
    width: 100%;
}
.p-paginator .p-dropdown{
    display: none;
}
.custom_width__ForTextWrap{
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.text-wrap .p-editor-container .p-editor-content .ql-editor:nth-child(1),.text-wrap{
    display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 3; /* Number of lines to show before truncating */
     overflow: hidden;
     height: 57px;
  } 
.text-wrap .p-editor-container .p-editor-content .ql-editor:nth-child(n+2){
    display: none;
  } 
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    background-color: #d6d6d6;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    background-color: #A41034;
    color: white;
    border: none;
}
.cursor-pointer {
    cursor: pointer;
  }
.p-paginator{
    justify-content: end !important;
}
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    color: white;
    background: #A41034;
    border-radius: 5px;
}
.saveBtn .p-button{
    background-color: white;
    color: #962336;
    margin: 5px;
    border: 0.5px solid #962336;
}
.saveBtn .p-button:hover{
    background-color: #F6F5FF;
    color: #962336;
    border: 0.5px solid #962336;
}
.publishBtn .p-button{
    margin: 5px;
}
.ash{
    color: #6b6b6b;
}
.bg-ash{
   background-color: #f2f2f2;
}
.bg-ashDark{
    background-color: #f1f1f1;
}
.aboutus-color{
    color: #a41034;
}
.color-ash{
    color: #8A8894;
}
.images-about {
    background: rgba(255, 255, 255, 0.00);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.20);
 
}
.p-inputtext:enabled:focus{
    box-shadow: none;
}

.pEditor__Readonly .p-editor-container .p-editor-toolbar.ql-snow{
    display: none;

}
.pEditor__Readonly .p-editor-container .p-editor-content.ql-snow{
    border: none;
}

.custom_css_uploadImage{
    border-style: dashed;
    height: 6rem;
    width: 6rem;
    background-color: #f6f5ff;
    cursor: pointer;
}

.bg-purple{
    background-color: #f6f5ff;
}

.bg-ash{
    background-color: rgb(47, 46, 46);
}
.bg-light-ash{
    background-color: #f6f6f6;
}
.color-ash{
    color: #616161;
}
.form-control::placeholder{
    color: #9A9A9A;
    font-size: 10px;
}
.maroon_btn .p-button, .maroon_btn .p-button:hover{
    background: #962336;
    color: white;
    box-shadow: none;
    border: none;
}

.custom-spinner .p-progress-spinner{
    height: 20px;
    width: 20px;
    padding: 0px 5px 0px 5px;
    margin: 0px 5px 0px 5px;
}
.custom-spinner .p-progress-spinner-svg{
    height: 50%;
    width: 50%;
}
:host::ng-deep .event_card .p-card{
    fill: var(--white, #FFF);
    stroke-width: 1px;
    stroke: var(--border, #BDBCDB);
    box-shadow: 0px -6px 0px 0px #A41034 inset;
    border: 0.5px solid #BDBCDB;
    padding: 2rem;
    height: 100%;
    cursor: pointer;
}
.news_list .ql-editor p-editor{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    
}
.filter-styles{
    background: #99A7BA;
    border-width: 0.666667px 0.666667px 0.666667px 0px;
    border-style: solid;
    height: 2.25rem;
    border-color: rgba(153, 167, 186, 0.27);
    border-radius: 0px 5px 5px 0px;
}
.bottom-line{
    border-bottom: 4px solid #a41034;
}
.transparent_btn .p-button, .transparent_btn .p-button:hover{
    background: white;
    color: #962336;
    box-shadow: none;
    border: 1px solid #962336 ;
}
.image-container {
    max-height: 400px; 
  }
  .img-fixed-size {
    object-fit: contain; 
  }
  .text-wrap1{
    -webkit-line-clamp: 2 !important;   
  }
  .custom_width__ForTextWrap1{
    width: 260px !important;
    height: 65px !important;
  }
  .has-search .form-control {
    padding-left: 2rem !important;

  }
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    pointer-events: none;
    color: #aaa;
  }

  .placeHolder-color::placeholder {
    color: black !important;
    font-size: 15px;
}
.session_tiomeout__popup .p-dialog .p-dialog-footer{
    display: flex;
    align-items: center;
    justify-content: end;
}
.text__wrap{
    display: block;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }